import { StorageSDK } from '@/packages/artebeaute-sdk/src'

/*function b64toBlob(b64Data, contentType, sliceSize) {
  contentType = contentType || "";
  sliceSize = sliceSize || 512;

  var byteCharacters = atob(b64Data);
  var byteArrays = [];

  for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    var slice = byteCharacters.slice(offset, offset + sliceSize);

    var byteNumbers = new Array(slice.length);
    for (var i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    var byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  var blob = new Blob(byteArrays, { type: contentType });
  return blob;
}*/

const uploadSingleBase64 = async (
  base64,
  directory = null,
  name = Date.now().toString()
  // nickname
) => {
  // var ImageURL = base64;
  // var block = ImageURL.split(";");
  // var contentType = block[0].split(":")[1];
  // var realData = block[1].split(",")[1];
  //
  // var blob = b64toBlob(realData, contentType);

  var formDataToUpload = new FormData()
  formDataToUpload.append('file', base64)
  formDataToUpload.append('fileName', name)

  const response = await StorageSDK.Upload.uploadImage(
    formDataToUpload,
    directory
  )

  // if (directory.startsWith('imagekit/')) {
  //   const fileName = response.url.split('/').pop()
  //
  //   return (
  //     'https://ik.imagekit.io/tiwpkbgdgfs/' +
  //     nickname +
  //     '/' +
  //     directory +
  //     '/' +
  //     fileName
  //   )
  // }

  return response.url
}

const uploadMultipleBase64 = async (base64Arr, directory = null) => {
  const imagesDataArr = []

  for (let base64 of base64Arr) {
    // var ImageURL = base64;
    // var block = ImageURL.split(";");
    // var contentType = block[0].split(":")[1];
    // var realData = block[1].split(",")[1];
    //
    // var blob = b64toBlob(realData, contentType);

    imagesDataArr.push(base64)
  }

  var formDataToUpload = new FormData()

  for (let img of imagesDataArr) {
    formDataToUpload.append('files[]', img)
  }

  const response = await StorageSDK.Upload.uploadImages(
    formDataToUpload,
    directory
  )

  return response.data
}
const deleteImage = async (imageFolder, imageName) => {
  const rep = await StorageSDK.Upload.deleteImage(imageFolder, imageName)
  return rep
}

const randomName = () => {
  return Math.random().toString(32).substr(5) + Date.now().toString()
}

export default {
  uploadSingleBase64,
  uploadMultipleBase64,
  deleteImage,
  randomName,
}
