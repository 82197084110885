<template>
  <div>
    <LightBox
      :media="mediaImages"
      ref="lightbox"
      :showLightBox="false"
      v-if="mediaImages.length"
    />
    <v-row align="stretch" v-if="single">
      <v-col
        v-for="(image, i) in valueData"
        :key="
          image && image.value && image.value.url
            ? image.value.url
            : 'new-image'
        "
        class="my-5 pa-0"
        cols="12"
        md="12"
        :style="'height: ' + height + ';'"
        style="position: relative"
      >
        <image-chooser
          v-model="valueData[i]"
          v-if="!image || !image.value"
          height="100%"
          :size="size"
          :title="title"
          :categories="categories"
          :imageSizes="imageSizes"
        />
        <div
          v-if="!image || !image.value"
          style="
            background: rgba(0, 0, 0, 0.5);
            color: white;
            position: absolute;
            left: 12px;
            right: 12px;
            bottom: 12px;
            text-align: center;
          "
        >
          {{ size[0] }}px {{ $t('other.on') }} {{ size[1] }}px
        </div>
        <v-img
          :src="image.value.url"
          v-if="image && image.value"
          class="mx-auto"
          height="100%"
          :width="width"
          @click="showImg(i)"
          style="position: relative"
        >
          <v-btn
            tile
            x-small
            color="primary"
            style="position: absolute; top: 0; right: 0"
            @click.stop="removeImage(i)"
          >
            <v-icon x-small>mdi-delete</v-icon>
          </v-btn>
        </v-img>
      </v-col>
    </v-row>
    <v-row align="stretch" v-else>
      <v-col
        v-for="(image, i) in valueData"
        :key="image.value && image.value.url ? image.value.url : 'new-image'"
        cols="12"
        :md="12 / perLine"
        :style="'height: ' + height + ';'"
        style="position: relative"
      >
        <image-chooser
          v-model="valueData[i]"
          v-if="!image.value"
          height="100%"
          :size="size"
          :categories="categories"
          :imageSizes="imageSizes"
        />
        <div
          v-if="!image.value"
          style="
            background: rgba(0, 0, 0, 0.5);
            color: white;
            position: absolute;
            left: 12px;
            right: 12px;
            bottom: 12px;
            text-align: center;
          "
        >
          {{ size[0] }}px {{ $t('other.on') }} {{ size[1] }}px
        </div>
        <v-img
          :src="image.value.url"
          v-if="image.value"
          height="100%"
          @click="showImg(i)"
          style="position: relative"
        >
          <v-btn
            tile
            x-small
            color="primary"
            style="position: absolute; top: 0; right: 0"
            @click.stop="removeImage(i)"
          >
            <v-icon x-small>mdi-delete</v-icon>
          </v-btn>
        </v-img>
      </v-col>
      <v-col
        cols="12"
        :md="12 / perLine"
        style="height: 150px"
        v-if="canAddNewImage"
      >
        <v-sheet
          color="#f0f0f0"
          height="100%"
          width="100%"
          tile
          @click="addNewImage"
          class="d-flex justify-center align-center"
        >
          <v-icon>mdi-plus</v-icon>
        </v-sheet>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import LightBox from 'vue-image-lightbox'
import ImageChooser from '@/components/images/imageChooser'
import 'vue-image-lightbox/dist/vue-image-lightbox.min.css'

export default {
  components: {
    ImageChooser,
    LightBox
  },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    single: {
      type: Boolean,
      default: false
    },
    height: {
      type: String,
      default: '150px'
    },
    width: {
      type: String,
      default: '100%'
    },
    size: {
      type: Array,
      default: () => [1600, 900]
    },
    perLine: {
      type: Number,
      default: 4
    },
    title: {
      type: String,
      default: () => null
    },
    updateData: {
      type: Array,
      default: () => []
    },
    categories: {
      type: Array,
      default: () => []
    },
    imageSizes: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      valueData: [],
      beforeValueData: [],
      itemIndex: 0
    }
  },
  created() {
    this.valueData = this.value
      .filter(i => !!i)
      .map(i => ({
        value: {
          url: i.url,
          urlLazy: i.lazyUrl,
          new: false,
          description: i.description,
          alt: i.alt
        }
      }))

    if (!this.valueData.length && this.single) {
      this.valueData.push(null)
    }

    this.valueData.forEach(i => this.beforeValueData.push(i))
  },
  watch: {
    valueData(newValueData) {
      this.$emit(
        'input',
        newValueData
          .filter(i => !!i)
          .filter(i => Object.keys(i).length)
          .map(i => ({
            url: i.value.url,
            urlLazy: i.value.urlLazy,
            blob: i.value.blob,
            blobLazy: i.value.blobLazy,
            description: i.description,
            alt: i.alt,
            new:
              this.beforeValueData.findIndex(
                ii => !!ii && !!i && ii.value.url === i.value.url
              ) === -1,
            category: i.value.category
          }))
      )
    },
    updateData(data) {
      if (data !== undefined && data !== null && data.length > 0) {
        this.valueData = data
          .filter(i => !!i)
          .map(i => ({
            value: {
              url: i.url,
              urlLazy: i.lazyUrl,
              new: false,
              description: i.description,
              alt: i.alt
            }
          }))

        if (!this.valueData.length && this.single) {
          this.valueData.push(null)
        }

        this.valueData.forEach(i => this.beforeValueData.push(i))
      }
    }
  },
  computed: {
    mediaImages() {
      return this.valueData
        .filter(i => !!i)
        .filter(i => Object.keys(i).length)
        .map(i => ({
          thumb: i.value.url,
          src: i.value.url
        }))
    },
    canAddNewImage() {
      let valid = true

      this.valueData.forEach(i => {
        if (!Object.keys(i).length) {
          valid = false
        }
      })

      return valid
    }
  },
  methods: {
    showImg(index) {
      this.$refs.lightbox.showImage(index)
    },
    addNewImage() {
      if (!this.canAddNewImage) {
        return
      }

      this.valueData.push({})
    },
    removeImage(imageIndex) {
      this.valueData.splice(imageIndex, 1)

      if (this.single) {
        if (!this.valueData.length) {
          this.valueData.push(null)
        }
      }

      this.$emit('delete', imageIndex)
    }
  }
}
</script>

<style></style>
