<template>
  <div>
    <div class="content-action-block mb-4" style="overflow-x: auto;">
      <div class="action-block">
        <v-dialog
          v-if="!$vuetify.breakpoint.mdAndUp"
          v-model="dialog"
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark v-bind="attrs" v-on="on" icon>
              <v-icon>mdi-cog</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-toolbar dark color="primary">
              <v-btn icon dark @click="dialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>
                {{ $t('settings.emails.settings') }}
              </v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <div class="pa-0">
              <div class="actions-popup-mobile" style="transform: scale(0.9)">
                <slot name="actionBlock" :isMobile="true" />
              </div>
            </div>
          </v-card>
        </v-dialog>

        <slot
          name="actionBlock"
          v-if="$vuetify.breakpoint.mdAndUp"
          :isMobile="false"
        />
      </div>
      <div class="ml-5 action-search">
        <slot name="actionSearch" :isMobile="!$vuetify.breakpoint.mdAndUp" />
      </div>
    </div>
    <div class="content-action-manage text-end mb-4">
      <slot name="manageContent" :isMobile="!$vuetify.breakpoint.mdAndUp" />
    </div>
    <div class="content">
      <div class="content-block">
        <slot name="contentBlock" :isMobile="!$vuetify.breakpoint.mdAndUp" />
      </div>
    </div>
    <div v-if="$vuetify.breakpoint.mdAndUp" class="footer-block mt-2">
      <slot name="footerBlock" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'moduleListLight',
  data() {
    return {
      dialog: false
    }
  }
}
</script>

<style>
.content-action-block {
  /* position: fixed; */
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  background: #f0f0f0;
  backdrop-filter: blur(5px);
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
  border-bottom: 1px solid #ccc;
}

.action-block {
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.actions-popup-mobile {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 25px;
}

.actions-popup-mobile .v-input {
  width: 100% !important;
}
</style>
