<template>
  <div style="height: 100%">
    <v-sheet
      color="#f0f0f0"
      height="100%"
      width="100%"
      tile
      class="d-flex justify-center align-center"
    >
      <ImageChooser
        @onSave="onCropperEnd"
        :size="size"
        :title="title"
        :categories="categories"
        :imageSizes="imageSizes"
      />
    </v-sheet>

    <!-- <div>
      <v-btn color="black" @click="onPickFile">
        <v-icon color="white">mdi-camera</v-icon>
      </v-btn>
      <input
        id="imageFile"
        type="file"
        style="display: none"
        ref="fileInput"
        @change="onFileChange"
        accept="image/*"
      />
    </div>
    <v-dialog
      v-model="dialog"
      persistent
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card align-center style="height: 100%">
        <v-card-title>Cropper une image</v-card-title>
        <div :id="'tui-' + tuiID" style="height: 100%"></div>
        <v-card-text>
          <Cropper
            :stencil-size="{
              width: 600,
              height: 300
            }"
            class="cropper"
            ref="cropper"
            :src="image"
            v-if="'a' === 'b'"
          />
        </v-card-text>
        <v-card-actions>
          <v-btn @click="cropImage">Cropper</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> -->
  </div>
</template>

<script>
import ImageChooser from '@/packages/cropper-editor/ImageChooser.vue'

// import { Cropper } from 'vue-advanced-cropper'
// import 'vue-advanced-cropper/dist/style.css'

// import ImageEditor from 'tui-image-editor'
// import 'tui-image-editor/dist/tui-image-editor.min.css'
// // import blackTheme from 'tui-image-editor/js/theme/black-theme'

export default {
  components: {
    ImageChooser
  },
  props: {
    size: {
      type: Array,
      default: () => [1920, 1080]
    },
    title: {
      type: String,
      default: () => null
    },
    categories: {
      type: Array,
      default: () => []
    },
    imageSizes: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    onCropperEnd(result) {
      const blob = this.base64ToBlob(result.desktop)
      const blobLazy = this.base64ToBlob(result.lazy)

      this.$emit('input', {
        type: 'blob',
        value: {
          blob: blob,
          blobLazy: blobLazy,
          url: result.desktop,
          urlLazy: result.lazy,
          alt: '',
          description: '',
          category: result.category
        }
      })
    },
    base64ToBlob(data) {
      let rImageType = /data:(image\/.+);base64,/
      let mimeString = ''
      let raw, uInt8Array, i, rawLength

      raw = data.replace(rImageType, function(header, imageType) {
        mimeString = imageType

        return ''
      })

      raw = atob(raw)
      rawLength = raw.length
      uInt8Array = new Uint8Array(rawLength) // eslint-disable-line

      for (i = 0; i < rawLength; i += 1) {
        uInt8Array[i] = raw.charCodeAt(i)
      }

      return new Blob([uInt8Array], { type: mimeString })
    }
  }
  // components: { Cropper },
  // props: {
  //   value: { type: Object, default: null }
  // },
  // data() {
  //   return {
  //     image: null,
  //     fileInput: null,
  //     loading: false,
  //     dialog: false,
  //     tuiInstance: null,
  //     tuiID: null
  //   }
  // },

  // beforeMount() {
  //   this.tuiID =
  //     (Math.random() * 1000).toString().split('.')[0] +
  //     '' +
  //     Date.now().toString()

  //   console.log('this.tuiID :>> ', this.tuiID)
  // },

  // methods: {
  // onPickFile() {
  //   console.log('onfpick :>> ', 'onfpick')
  //   document.getElementById('imageFile').value = null

  //   this.$refs.fileInput.click()
  // },
  //   // onFileChange(events) {
  //   //   var input = events.target;
  //   //   // Ensure that you have a file before attempting to read it
  //   //   if (input.files && input.files[0]) {
  //   //     // create a new FileReader to read this image and convert to base64 format
  //   //     var reader = new FileReader();
  //   //     // Define a callback function to run, when FileReader finishes its job
  //   //     reader.onload = (e) => {
  //   //       // Note: arrow function used here, so that "this.imageData" refers to the imageData of Vue component
  //   //       // Read image as base64 and set to imageData
  //   //       this.image = e.target.result;
  //   //     };
  //   //     // Start the reader job - read file as a data url (base64 format)
  //   //     reader.readAsDataURL(input.files[0]);
  //   //     this.dialog = true;
  //   //   }
  //   // },
  //   onFileChange(e) {
  //     this.dialog = true

  //     this.$nextTick(() => {
  //       const file = e.target.files[0]
  //       const reader = new FileReader()

  //       reader.addEventListener(
  //         'load',
  //         () => {
  //           this.tuiInstance = new ImageEditor(
  //             document.querySelector('#tui-' + this.tuiID),
  //             {
  //               includeUI: {
  //                 loadImage: {
  //                   path: reader.result,
  //                   name: 'Image'
  //                 },
  //                 initMenu: 'filter',
  //                 menuBarPosition: 'bottom',
  //                 theme: {
  //                   'header.display': 'none'
  //                 }
  //               },
  //               cssMaxWidth: 700,
  //               cssMaxHeight: 500,
  //               selectionStyle: {
  //                 cornerSize: 20,
  //                 rotatingPointOffset: 70
  //               }
  //             }
  //           )
  //         },
  //         false
  //       )

  //       if (file) {
  //         reader.readAsDataURL(file)
  //       }
  //     })
  //   },
  //   cropImage() {
  //     const dataURL = this.tuiInstance.toDataURL()
  //     const blob = this.base64ToBlob(dataURL)

  //     this.$emit('input', {
  //       type: 'blob',
  //       value: {
  //         blob: blob,
  //         url: dataURL,
  //         alt: '',
  //         description: ''
  //       }
  //     })
  //     this.dialog = false
  //   },
  // base64ToBlob(data) {
  //   let rImageType = /data:(image\/.+);base64,/
  //   let mimeString = ''
  //   let raw, uInt8Array, i, rawLength

  //   raw = data.replace(rImageType, function(header, imageType) {
  //     mimeString = imageType

  //     return ''
  //   })

  //   raw = atob(raw)
  //   rawLength = raw.length
  //   uInt8Array = new Uint8Array(rawLength) // eslint-disable-line

  //   for (i = 0; i < rawLength; i += 1) {
  //     uInt8Array[i] = raw.charCodeAt(i)
  //   }

  //   return new Blob([uInt8Array], { type: mimeString })
  // }
  //   // cropImage() {
  //   //   const { canvas } = this.$refs.cropper.getResult()
  //   //   if (canvas) {
  //   //     // const imgUrl = blob.canvas.toDataURL("image/jpeg");
  //   //     /*     const { canvas } = this.$refs.cropper.getResult();
  //   //     if (canvas) {
  //   //       const form = new FormData();
  //   //       canvas.toBlob((blob) => {
  //   //         form.append('file', blob);
  //   //         fetch('http://example.com/upload/', {
  //   //           method: 'POST',
  //   //           body: form,
  //   //         });
  //   //       }, 'image/jpeg');
  //   //     }*/

  //   //     canvas.toBlob(blob => {
  //   //       console.log('1')
  //   //       this.$emit('input', {
  //   //         type: 'blob',
  //   //         value: {
  //   //           blob: blob,
  //   //           url: canvas.toDataURL('image/jpeg'),
  //   //           alt: '',
  //   //           description: ''
  //   //         }
  //   //       })
  //   //       this.dialog = false
  //   //     }, 'image/jpeg')
  //   //   }
  //   // }
  // }
}
</script>

<style>
/* .cropper {
  width: 500px;
  height: 300px;
  background: #ddd;
} */
</style>
