<template>
  <div>
    <div>
      <!--
      <v-btn color="black" @click="onPickFileWithoutCropper" text block>
        <v-icon left>mdi-image</v-icon>
        <span v-if="title">{{ title }}</span>
        <span v-else>{{ $t('image.image') }}</span>
      </v-btn>
      -->
      <v-btn color="black" @click="onPickFile" text class="mt-1 mb-5" block>
        <v-icon left>mdi-image-edit</v-icon>
        Editeur
      </v-btn>
      <!--      <cropper-->
      <!--        :resolution-cropper="size"-->
      <!--        :categories="categories"-->
      <!--        :imageSizes="imageSizes"-->
      <!--        @save="save"-->
      <!--      />-->
      <input
        type="file"
        @change="processFileInput"
        ref="fileInput"
        id="imageFile"
        style="display: none"
        accept="image/*"
      />
    </div>

    <v-dialog
      v-model="dialog"
      fullscreen
      transition="dialog-bottom-transition"
      v-if="mode === 'cropper'"
    >
      <v-card class="height-m-64">
        <v-toolbar dark color="blue-grey darken-4">
          <v-toolbar-title class="cropper-title">
            {{ $t('image.editAnImage') }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text @click="onCancel">
              <v-icon class="mr-2">mdi-cancel</v-icon>
              {{ $t('image.cancel') }}
            </v-btn>
            <v-btn dark text @click="onSave">
              <v-icon class="mr-2">mdi-content-save</v-icon>
              {{ $t('image.validate') }}
            </v-btn>
          </v-toolbar-items>
          <v-spacer class="mobile-spacer"></v-spacer>
        </v-toolbar>
        <div class="height-m-64 cropper-content">
          <div class="output-image height-full" v-if="imageBase64">
            <img
              :src="imageBase64"
              alt="Ouput Image"
              v-if="imageBase64"
              id="cropper-result-img"
              style="width: 100%"
            />
          </div>
          <div class="right-panel">
            <div class="btn-groups">
              <v-btn type="button" dark @click="actionHistory(-1)">
                <v-icon>mdi-undo</v-icon>
              </v-btn>
              <v-btn type="button" dark @click="actionHistory(1)">
                <v-icon>mdi-redo</v-icon>
              </v-btn>
            </div>
            <v-btn type="button" dark block @click="actionRotate(90)">
              <v-icon class="mr-2">mdi-rotate-left</v-icon>
              {{ $t('image.turnLeft') }}
            </v-btn>
            <v-btn type="button" dark block @click="actionRotate(-90)">
              <v-icon class="mr-2">mdi-rotate-right</v-icon>
              {{ $t('image.turnRight') }}
            </v-btn>
            <v-btn type="button" dark block @click="actionFlip(true, false)">
              <v-icon class="mr-2">mdi-flip-horizontal</v-icon>
              {{ $t('image.flipHorizontally') }}
            </v-btn>
            <v-btn type="button" dark block @click="actionFlip(false, true)">
              <v-icon class="mr-2">mdi-flip-vertical</v-icon>
              {{ $t('image.flipVertically') }}
            </v-btn>
            <v-btn type="button" dark block @click="actionGreyscale()">
              <v-icon class="mr-2">mdi-image-filter-black-white</v-icon>
              {{ $t('image.blackAndWhite') }}
            </v-btn>
            <v-btn type="button" dark block @click="actionBlur()">
              <v-icon class="mr-2">mdi-image-filter-black-white</v-icon>
              {{ $t('image.blur') }}
            </v-btn>
          </div>
        </div>
      </v-card>
      <div class="loading-overlay" v-if="processing">
        <v-progress-circular indeterminate color="amber"></v-progress-circular>
      </div>
    </v-dialog>

    <div class="loading-overlay" v-if="processing && mode === 'simple'">
      <v-progress-circular indeterminate color="amber"></v-progress-circular>
    </div>

    <!-- <v-dialog
      v-model="dialog"
      fullscreen
      transition="dialog-bottom-transition"
      v-if="mode === 'simple'"
    >
      <v-card class="height-m-64">
        <v-toolbar dark color="blue-grey darken-4"> </v-toolbar>
        <div class="height-m-64 cropper-content">
          <div class="output-image height-full">
            <img
              :src="imageBase64"
              alt="Ouput Image"
              v-if="imageBase64"
              id="cropper-result-img"
            />
          </div>
        </div>
      </v-card>
      
    </v-dialog> -->
  </div>
</template>

<script>
/* eslint-disable no-undef */
// import smartcrop from 'smartcrop'
// import Selector from 'image-area-selector'
import imageCompression from 'browser-image-compression'
// import Cropper from '../../components/images/cropper'
import Croppr from 'croppr'
// import swal from 'sweetalert'

import 'croppr/dist/croppr.min.css'

export default {
  // components: { Cropper },
  props: {
    wantedResults: {
      type: Object,
      default: () => {
        return {
          desktop: { width: 1920, height: 1080 },
          mobile: { width: 400, height: 800 },
        }
      },
    },
    size: {
      type: Array,
      default: () => [1920, 1080],
    },
    title: {
      type: String,
      default: () => null,
    },
    categories: {
      type: Array,
      default: () => [],
    },
    imageSizes: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      dialog: false,
      fileInput: null,
      imageBase64: null,
      processing: false,
      history: [],
      currentHistoryIndex: 0,
      selector: null,
      mode: null,
      imgCropLoaded: false,
    }
  },
  methods: {
    onPickFile() {
      this.imgCropLoaded = false
      this.mode = 'cropper'
      this.imageBase64 = null
      this.history = []
      this.currentHistoryIndex = 0
      this.$refs.fileInput.click()
    },
    onPickFileWithoutCropper() {
      this.mode = 'simple'
      this.imageBase64 = null
      this.$refs.fileInput.click()
    },
    onCancel() {
      this.$emit('onCancel')
      this.dialog = false
    },
    async onSave() {
      if (this.mode === 'cropper') {
        this.processing = true
        const finalResults = {}
        for (const [key] of Object.entries(this.wantedResults)) {
          // const cropResult = await smartcrop.crop(
          //   document.querySelector('#cropper-result-img'),
          //   {
          //     width: value.width,
          //     height: value.height
          //   }
          // )
          // const rect = cropResult.topCrop
          // const rectResult = this.selector.capture(true)
          const rectResult = this.selector.getValue()
          const jimpImage = await Jimp.read(this.imageBase64)
          const result64 = await jimpImage
            .crop(
              rectResult.x,
              rectResult.y,
              rectResult.width,
              rectResult.height
            )
            .quality(100)
            .getBase64Async(Jimp.MIME_PNG)
          // const result64 = await jimpImage
          //   .crop(rect.x, rect.y, rect.width, rect.height)
          //   .quality(80)
          //   .getBase64Async(Jimp.MIME_PNG)
          const options = {
            maxSizeMB: 100.25, // 0.25 (default: Number.POSITIVE_INFINITY)
          }
          const result = await imageCompression(this.toBlog(result64), options)
          finalResults[key] = await this.toBase64(result) // result64
        }
        const rectResult = this.selector.getValue() // this.selector.capture(true)
        const jimpImage = await Jimp.read(this.imageBase64)
        const result64 = await jimpImage
          .crop(rectResult.x, rectResult.y, rectResult.width, rectResult.height)
          .quality(50)
          .blur(90)
          .getBase64Async(Jimp.MIME_PNG)
        // const result64 = await jimpImage
        //   .crop(rect.x, rect.y, rect.width, rect.height)
        //   .quality(80)
        //   .getBase64Async(Jimp.MIME_PNG)
        const options = {
          maxSizeMB: 0.1,
        }
        const result = await imageCompression(this.toBlog(result64), options)
        finalResults['lazy'] = await this.toBase64(result) // result64
        this.$emit('onSave', finalResults)
        this.dialog = false
        this.processing = false
      } else {
        this.processing = true
        const finalResults = {}
        for (const [key] of Object.entries(this.wantedResults)) {
          // const cropResult = await smartcrop.crop(
          //   document.querySelector('#cropper-result-img'),
          //   {
          //     width: value.width,
          //     height: value.height
          //   }
          // )
          // const rect = cropResult.topCrop
          const jimpImage = await Jimp.read(this.imageBase64)
          const result64 = await jimpImage
            .quality(100)
            .getBase64Async(Jimp.MIME_PNG)
          // const result64 = await jimpImage
          //   .crop(rect.x, rect.y, rect.width, rect.height)
          //   .quality(80)
          //   .getBase64Async(Jimp.MIME_PNG)
          const options = {
            maxSizeMB: 100.25, // (default: Number.POSITIVE_INFINITY)
          }
          const result = await imageCompression(this.toBlog(result64), options)
          finalResults[key] = await this.toBase64(result) // result64
        }

        const jimpImage = await Jimp.read(this.imageBase64)
        const result64 = await jimpImage
          .quality(50)
          .blur(90)
          .getBase64Async(Jimp.MIME_PNG)
        // const result64 = await jimpImage
        //   .crop(rect.x, rect.y, rect.width, rect.height)
        //   .quality(80)
        //   .getBase64Async(Jimp.MIME_PNG)
        const options = {
          maxSizeMB: 0.1,
        }
        const result = await imageCompression(this.toBlog(result64), options)
        finalResults['lazy'] = await this.toBase64(result) // result64
        this.$emit('onSave', finalResults)
        this.dialog = false
        this.processing = false
      }
    },
    toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = (error) => reject(error)
      })
    },
    toBlog(dataURI) {
      var byteString = atob(dataURI.split(',')[1])
      var ab = new ArrayBuffer(byteString.length)
      var ia = new Uint8Array(ab)
      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i)
      }
      return new Blob([ab], { type: 'image/png' })
    },
    async processFileInput(e) {
      const input = e.target
      if (input.files && input.files[0]) {
        if (this.mode === 'cropper') {
          this.imageBase64 = await this.toBase64(input.files[0])
          this.dialog = true
          this.processing = true
          Jimp.read(this.imageBase64)
            .then(async (image) => {
              this.imageBase64 = await image
                // .scaleToFit(this.size[0], this.size[1])
                .getBase64Async(Jimp.MIME_PNG)
              this.history.push(this.imageBase64)
              this.processing = false

              this.$nextTick(() => {
                setTimeout(() => {
                  this.selector = new Croppr('#cropper-result-img', {
                    startSize: [100, 100, '%'],
                  })
                }, 100)

                this.imgCropLoaded = true

                // this.selector = new Selector({
                //   imgId: 'cropper-result-img',
                //   keepAspect: false, // Allow any ratio, or keep the image ratio during resizing
                //   customRatio: true, // Use image ratio, or maxWidth/maxHeight ratio
                //   relative: true, // Uses dimensions as native or relative
                //   maxWidth: 1920, // Maximum allowed width
                //   maxHeight: 1080 // Maximum allowed height
                // })
                // this.selector.setup(true)
              })
            })
            .catch((err) => {
              this.processing = false
              console.log('err :>> ', err)
            })
        } else {
          this.imageBase64 = await this.toBase64(input.files[0])
          this.dialog = true
          this.processing = true
          Jimp.read(this.imageBase64)
            .then(async (image) => {
              // if (
              //   (image.bitmap.width < this.size[0] ||
              //     image.bitmap.height < this.size[1]) &&
              //   false
              // ) {
              //   this.dialog = false
              //   this.processing = false
              //   swal({
              //     icon: 'error',
              //     text:
              //       this.$t('image.dimensionsOfSelectedImageNot') +
              //       ' (' +
              //       this.size[0] +
              //       'px ' +
              //       this.$t('other.on') +
              //       ' ' +
              //       this.size[1] +
              //       'px).'
              //   })
              //   return
              // }

              this.imageBase64 = await image
                // .scaleToFit(this.size[0], this.size[1])
                .getBase64Async(Jimp.MIME_PNG)
              this.history.push(this.imageBase64)
              this.processing = false

              this.onSave()
            })
            .catch((err) => {
              this.processing = false
              console.log('err :>> ', err)
            })
        }
      }
    },
    actionHistory(direction) {
      if (direction < 0) {
        if (this.currentHistoryIndex > 0) {
          this.currentHistoryIndex--
          this.imageBase64 = this.history[this.currentHistoryIndex]
        }
      } else if (direction > 0) {
        if (this.currentHistoryIndex < this.history.length - 1) {
          this.currentHistoryIndex++
          this.imageBase64 = this.history[this.currentHistoryIndex]
        }
      }

      this.refreshImg()
    },
    refreshImg() {
      const tmp = this.imageBase64

      this.imageBase64 = null
      this.imgCropLoaded = false

      this.$nextTick(() => {
        this.imageBase64 = tmp
        this.$nextTick(() => {
          setTimeout(() => {
            this.selector = new Croppr('#cropper-result-img', {
              startSize: [100, 100, '%'],
            })
          }, 100)

          this.imgCropLoaded = true
        })
      })
    },
    actionRotate(degrees) {
      this.history.length = this.currentHistoryIndex + 1
      this.processing = true
      Jimp.read(this.imageBase64)
        .then(async (image) => {
          this.imageBase64 = await image
            .rotate(degrees)
            .getBase64Async(Jimp.MIME_PNG)
          this.processing = false
          this.history.push(this.imageBase64)
          this.currentHistoryIndex++

          this.refreshImg()
        })
        .catch((err) => {
          this.processing = false
          console.log('err :>> ', err)
        })
    },
    actionFlip(x, y) {
      this.history.length = this.currentHistoryIndex + 1
      this.processing = true
      Jimp.read(this.imageBase64)
        .then(async (image) => {
          this.imageBase64 = await image
            .flip(x, y)
            .getBase64Async(Jimp.MIME_PNG)
          this.processing = false
          this.history.push(this.imageBase64)
          this.currentHistoryIndex++

          this.refreshImg()
        })
        .catch((err) => {
          this.processing = false
          console.log('err :>> ', err)
        })
    },
    actionGreyscale() {
      this.history.length = this.currentHistoryIndex + 1
      this.processing = true
      Jimp.read(this.imageBase64)
        .then(async (image) => {
          this.imageBase64 = await image
            .greyscale()
            .getBase64Async(Jimp.MIME_PNG)
          this.processing = false
          this.history.push(this.imageBase64)
          this.currentHistoryIndex++

          this.refreshImg()
        })
        .catch((err) => {
          this.processing = false
          console.log('err :>> ', err)
        })
    },
    actionBlur() {
      this.history.length = this.currentHistoryIndex + 1
      this.processing = true
      Jimp.read(this.imageBase64)
        .then(async (image) => {
          this.imageBase64 = await image.blur(5).getBase64Async(Jimp.MIME_PNG)
          this.processing = false
          this.history.push(this.imageBase64)
          this.currentHistoryIndex++

          this.refreshImg()
        })
        .catch((err) => {
          this.processing = false
          console.log('err :>> ', err)
        })
    },

    save(base64, category) {
      this.$emit('onSave', { desktop: base64, lazy: base64, category })
    },
  },
}
</script>

<style>
.height-m-64 {
  height: calc(100% - 94px);
}
.height-full {
  height: 100%;
}
.cropper-content {
  display: flex;
  position: relative;
}
.output-image {
  flex-basis: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000;
  padding: 25px;
}
.output-image .croppr-container,
.output-image .croppr {
  min-height: 100%;
  max-height: calc(100vh - 94px - 50px);
}
.output-image img {
  object-fit: contain;
  width: 100%;
  max-height: calc(100vh - 94px - 50px);
}
.right-panel {
  width: 500px;
  background-color: #1e1e1e;
  overflow-y: auto;
  overflow-x: hidden;
}
.right-panel button {
  padding: 50px 25px !important;
  border-radius: 0;
  border: 1px solid #000;
}
.right-panel button span {
  justify-content: flex-start;
}
.mobile-spacer {
  display: none;
}
@media screen and (max-width: 768px) {
  .cropper-title {
    display: none;
  }
  .output-image {
    height: 35%;
    flex-basis: 35%;
  }
  .right-panel {
    height: 65%;
    flex-basis: 65%;
    width: 100%;
  }
  .cropper-content {
    flex-direction: column;
  }
  .mobile-spacer {
    display: block;
  }
}
.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-groups {
  display: flex;
  align-items: center;
  width: 100%;
}
.btn-groups button {
  flex-basis: 50%;
  padding: 25px 25px !important;
  border-radius: 0;
  border: 1px solid #000;
}
.btn-groups button span {
  justify-content: center;
}
#selector-container {
  display: inline-block;
  overflow: hidden;
}
#selector-move {
  position: absolute;
  box-shadow: 0 0 0 20000px rgba(0, 0, 0, 0.5);
  /* border: 3px dotted black; */
  cursor: move;
}
#selector-resize {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
}
#selector-resize div {
  position: absolute;
  width: 15px;
  height: 15px;
}
#selector-resize .nw {
  top: 0;
  left: 0;
  border-top: 5px solid black;
  border-left: 5px solid black;
  cursor: nw-resize;
}
#selector-resize .ne {
  top: 0;
  right: 0;
  border-top: 5px solid black;
  border-right: 5px solid black;
  cursor: ne-resize;
}
#selector-resize .sw {
  bottom: 0;
  left: 0;
  border-bottom: 5px solid black;
  border-left: 5px solid black;
  cursor: sw-resize;
}
#selector-resize .se {
  bottom: 0;
  right: 0;
  border-bottom: 5px solid black;
  border-right: 5px solid black;
  cursor: se-resize;
}
/*#cropper-result-img {*/
/*  width: 100%;*/
/*  height: 100%;*/
/*  object-fit: contain;*/
/*}*/
/*.output-image #selector-container {*/
/*  max-height: 100%;*/
/*  height: 100%;*/
/*}*/
</style>
