<template>
  <div style="position: relative">
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <div
          style="
            display: inline-block;
            padding: 0 10px;
            background-color: #eee;
            border: 1px solid #ccc;
            position: absolute;
            top: 0;
            right: 0;
            color: #999;
            font-size: 12px;
            z-index: 5;
            cursor: pointer;
          "
          v-bind="attrs"
          v-on="on"
        >
          {{ $t('other.language') }} :
          <strong style="text-transform: uppercase">
            {{ currentLangCode }}
          </strong>
        </div>
      </template>
      <v-list>
        <v-list-item
          v-for="(item, index) in getLanguagesItems"
          :key="'lang-' + index"
        >
          <v-list-item-title @click="currentLangCode = item.value">{{
            item.text
          }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <div style="padding-top: 20px">
      <vue-editor
        class="text-editor"
        :style="{ height }"
        v-model="val"
        ref="qeditor"
        :placeholder="placeholder"
      />
    </div>
  </div>
</template>

<script>
import { langs } from '@/helpers/langs'
import { VueEditor } from 'vue2-editor'

export default {
  components: { VueEditor },
  props: {
    height: {
      type: String,
      default: '',
    },
    value: {
      type: Object,
      default: () => {},
    },
    placeholder: {
      type: String,
      default: '',
    },
    label: { type: String, default: '' },
    orientation: { type: String, default: 'horizontal' },
  },
  data() {
    return {
      dataValue: this.value,
      val: null,
      currentLangCode: 'fr',
    }
  },
  watch: {
    value: {
      handler: function (val) {
        this.dataValue = val
        if (!this.dataValue) {
          this.$set(this, 'dataValue', {})
        }

        Object.entries(langs).forEach(([key]) => {
          if (this.dataValue[key]) {
            this.$set(this.dataValue, key, this.dataValue[key])
          } else {
            this.$set(this.dataValue, key, '')
          }
        })
        this.val = this.dataValue ? this.dataValue[this.currentLangCode] : null
      },
      deep: true,
    },
    val() {
      this.dataValue[this.currentLangCode] = this.val
      this.$emit('input', this.dataValue)
    },
    currentLangCode() {
      this.val = this.dataValue[this.currentLangCode]
    },
  },
  beforeMount() {
    if (!this.dataValue) {
      this.$set(this, 'dataValue', {})
    }

    Object.entries(langs).forEach(([key]) => {
      if (this.dataValue[key]) {
        this.$set(this.dataValue, key, this.dataValue[key])
      } else {
        this.$set(this.dataValue, key, '')
      }
      // this.dataValue[key] = this.dataValue[key] ? this.dataValue[key] : "";
    })

    this.val = this.dataValue[this.currentLangCode]
  },
  computed: {
    getLanguagesItems() {
      return Object.entries(langs).map(([key, value]) => {
        return {
          value: key,
          text: value,
        }
      })
    },
  },
  methods: {
    insertText(text) {
      this.$refs.qeditor.quill.editor.insertText(
        this.$refs.qeditor.quill.selection.savedRange.index,
        text
      )

      this.val = this.$refs.qeditor.quill.root.innerHTML
    },
  },
}
</script>

<style scoped></style>
