<template>
  <div v-if="displayAll" class="content">
    <div
      class="back-filter"
      :style="{ width: widthFilter }"
      @click="$emit('close')"
    />
    <div class="content-edit" :style="{ width: widthEdit }">
      <div class="header-edit primary" :style="{ opacity: contentOpacity }">
        <div class="title-edit">{{ title }}</div>
        <div class="action-header-edit">
          <v-btn v-ripple @click="close" tile text dark>
            {{ $t('edit.cancel') }}
          </v-btn>
          <v-btn v-ripple @click="validModal" color="success" tile>
            <!-- <span>Valider</span> -->
            <v-icon>mdi-check-bold</v-icon>
          </v-btn>
        </div>
      </div>
      <div
        v-show="displayBody"
        class="edit-section"
        :style="{ opacity: contentOpacity }"
      >
        <slot name="body" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'header-app-menu',
  props: {
    display: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: 'Édition'
    }
  },
  methods: {
    anim() {
      if (this.display) {
        this.displayAll = true
        setTimeout(() => {
          this.widthFilter = '100%'
          this.widthEdit =
            this.$vuetify.breakpoint.width < 900 ? '100%' : '800px'
        }, 10)
        setTimeout(() => {
          this.displayBody = true
        }, 300)
        setTimeout(() => {
          this.contentOpacity = 1
        }, 400)
      } else {
        this.widthFilter = 0
        this.widthEdit = 0
        this.contentOpacity = 0
        this.displayBody = false

        setTimeout(() => {
          this.displayAll = false
        }, 510)
      }
    },
    validModal() {
      this.$emit('actionModal', true)
    },
    close() {
      this.$emit('actionModal', false)
    }
  },
  mounted() {
    this.anim()
  },
  watch: {
    display() {
      this.anim()
    }
  },
  data: () => {
    return {
      widthFilter: 0,
      widthEdit: 0,
      displayAll: false,
      contentOpacity: 0,
      displayBody: false
    }
  }
}
</script>

<style scoped>
.content {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 20;
}

.back-filter {
  width: 0;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.1);
  backdrop-filter: grayscale(100%);
  cursor: pointer;
  transition: width 0.5s;
  transition-timing-function: ease;
}

.content-edit {
  width: 0;
  height: 100%;
  top: 0;
  right: 0;
  position: absolute;
  background-color: white;
  transition: width 0.5s;
  transition-timing-function: ease;
  overflow: hidden;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.3);
  display: grid;
  grid-template: auto 1fr / 1fr;
}

.header-edit {
  width: 100%;
  padding: 50px 60px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: opacity 0.2s;
  padding-bottom: 16px;
  color: #fff;
}

.action-header-edit {
  display: flex;
  column-gap: 20px;
}

.edit-section {
  width: 100%;
  height: 100%;
  transition: opacity 0.2s;
  overflow-y: auto;
}

.title-edit {
  font-weight: 700;
  color: #fff;
  font-size: 1.25rem;
}

.annuler-btn,
.valider-btn {
  font-size: 1.1rem;
  text-transform: uppercase;
  font-weight: 700;
  padding: 10px 20px;
  border-radius: 2px;
  cursor: pointer;
}

.annuler-btn {
  color: #600f0f;
}

.valider-btn {
  color: #0a6302;
  background-color: #daf3c6;
  display: flex;
  align-items: center;
  column-gap: 10px;
}

@media only screen and (min-width: 700px) {
  .edit-section::-webkit-scrollbar-button {
    display: none;
    height: 5px;
    border-radius: 0px;
    background-color: #aaa;
  }

  .edit-section::-webkit-scrollbar-button:hover {
    background-color: #aaa;
  }

  .edit-section::-webkit-scrollbar-thumb {
    background-color: #c3c3c3;
    border-radius: 10px;
  }

  .edit-section::-webkit-scrollbar-thumb:hover {
    background-color: #5f5f5f;
  }

  .edit-section::-webkit-scrollbar-track {
    border-radius: 0px;
  }

  .edit-section::-webkit-scrollbar-track:hover {
    background-color: #e7e7e7;
  }

  .edit-section::-webkit-scrollbar {
    width: 7px;
  }
}

@media all and (max-width: 800px) {
  .header-edit {
    padding: 30px 15px 0;
  }
}
</style>
